<template>
  <div style="height: 100%">
    <v-carousel
      :interval="interval"
      :height="height"
      :style="$vuetify.breakpoint.smAndDown ? { 'max-height': '210px' } : {}"
      show-arrows-on-hover
      hide-delimiters
      hide-delimiter-background
      cycle
      v-model="current"
    >
      <v-carousel-item v-for="(item, i) in banners" :key="i">
        <v-img
          aspect-ratio="1.6"
          height="100%"
          :style="
            $vuetify.breakpoint.smAndDown ? { 'max-height': '210px' } : {}
          "
          :src="item"
        ></v-img>
      </v-carousel-item>
    </v-carousel>
    <div class="flex-center-row mt-5">
      <div
        v-for="(item, i) in banners"
        :key="i"
        :class="{ 'carousel-dots-active': i === current }"
        class="carousel-dots mr-2"
        @click="current = i"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    banners: {
      default: []
    },
    interval: {
      default: 5000
    },
    height: {
      default: '100%'
    }
  },
  data: () => ({
    current: 0
  })
}
</script>

<style scoped lang="scss">
.carousel-dots {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #c4c4c4;
  cursor: pointer;
}
.carousel-dots-active {
  background: var(--bkk-theme);
}
</style>
