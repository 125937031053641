import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Cart from './modules/Cart'
import Auth from './modules/Auth'
import Categories from './modules/Categories'
import Products from './modules/Products'
import Address from './modules/Address'
import Organization from './modules/Organization'
import Live from './modules/Live'
import Order from './modules/Order'
import Payment from './modules/Payment'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Cart,
    Auth,
    Address,
    Categories,
    Products,
    Organization,
    Live,
    Order,
    Payment
  },
  plugins: [createPersistedState()]
})
