<template>
  <div class="pa-5 pa-md-12 footer-bg">
    <v-img
      height="100%"
      style="position: absolute; left: 0; right: 0; top: 0; opacity: 45%"
      :src="require('../assets/img/footer-bg.png')"
    >
      <div class="full-absolute" style="background: rgba(0, 0, 0, 0.35)"></div>
    </v-img>
    <v-layout>
      <v-flex align-self-center>
        <v-img
          style="opacity: 81%"
          :src="require('../assets/img/my-bkk.png')"
          contain
          max-height="100%"
          :width="$vuetify.breakpoint.smAndDown ? '60px' : '120px'"
        ></v-img>
      </v-flex>
      <v-flex>
        <div
          class="mb-2"
          :class="{
            'txt-bigger': $vuetify.breakpoint.mdAndUp,
            'txt-detail': $vuetify.breakpoint.smAndDown
          }"
          v-for="(item, i) in firstCol"
          :key="i"
        >
          <a :href="item.path" style="color: black; text-underline-style: none">
            <span class="pointer" style="position: relative">
              {{ item.text }}
            </span>
          </a>
        </div>
      </v-flex>
      <v-flex>
        <div
          class="mb-2"
          :class="{
            'txt-bigger': $vuetify.breakpoint.mdAndUp,
            'txt-detail': $vuetify.breakpoint.smAndDown
          }"
          v-for="(item, i) in secondCol"
          :key="i"
        >
          <span class="pointer" style="position: relative">{{
            item.text
          }}</span>
        </div>
        <div class="border-line mb-3 mt-n1"></div>
        <v-btn
          icon
          :small="$vuetify.breakpoint.smAndDown"
          class="pa-2"
          v-for="(item, i) in socialIcon"
          :key="i + 1"
          @click="openLink(item.link)"
        >
          <v-img
            contain
            :width="$vuetify.breakpoint.smAndDown ? '15px' : '20px'"
            :src="item.src"
          ></v-img>
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      firstCol: [
        // {
        //     text: 'New Arrivals',
        //     path: ''
        // },
        {
          text: 'Shop',
          path: '/'
        }
      ],
      secondCol: [
        {
          text: 'Contact us',
          path: ''
        }
      ],
      socialIcon: [
        {
          src: require('../assets/img/social/fb.png'),
          link: 'https://www.facebook.com/mybkkshop/'
        },
        {
          src: require('../assets/img/social/phone.png'),
          link: ''
        },
        {
          src: require('../assets/img/social/ig.png'),
          link: 'https://www.instagram.com/mybkkshop/'
        }
      ]
    }
  },
  methods: {
    openLink(link) {
      if (link) {
        window.open(link)
      }
    }
  }
}
</script>

<style scoped>
.footer-bg {
  position: relative;
  background: transparent;
  overflow: hidden;
}
.border-line {
  position: relative;
  border-bottom: solid 2px var(--bkk-darkblue);
  width: 80px;
}
</style>
