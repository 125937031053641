import Service from '../../services'

const state = {
    orders: [],
    order: {}
}

const getters = {
    getOrdersToBeShipping (state) {
        return state.orders.filter(x => {
            // if (x.) return x
        })
    },
    getOrdersToReceive (state) {
        return state.orders.filter(x => {
            // if (x.) return x
        })
    },
    getCompleteOrders (state) {
        return state.orders.filter(x => {
            // if (x.) return x
        })
    }
}

const actions = {
    onGetAllOrders ({ commit }, token) {
        return Service.order.getAllOrders(token).then(res => {
            if (res.status === 200) {
                commit('setOrders', res.data.orders)
            } else {
                commit('clearOrders')
            }
        })
    },

    onGetOrderById ({ commit }, data) {
        return Service.order.getOrder(data).then(res => {
            if (res.status === 200) {
                commit('setOrder', res.data.order)
            }
        })
    }
}

const mutations = {
    setOrders (state, data) {
        state.orders = data
    },
    setOrder (state, data) {
        state.order = data
    },
    clearOrders (state) {
        state.orders = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
