export function initFbSdk(appId) {
    return new Promise(resolve => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: appId,
                cookie: true,
                xfbml: true,
                version: "v9.0"
            });
        };

        (function() {
            var js,
                fjs = document.getElementsByTagName('script')[0];
            if (document.getElementById('facebook-jssdk')) {
                return;
            }
            js = document.createElement('script');
            js.id = 'facebook-jssdk';
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })();
    });
}
