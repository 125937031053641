<template>
  <v-select
    dense
    outlined
    hide-details
    :placeholder="placeholder"
    class="select-box-theme"
    :background-color="backgroundColor"
    :full-width="fullWidth"
    :items="items"
    :item-text="item_text"
    :item-value="item_value"
    :height="height"
    menu-props="offset-y"
    append-icon="expand_more"
    :value="value"
    @change="eventChange"
  />
</template>

<script>
export default {
  name: 'SelectBox',
  props: {
    items: {
      type: Array,
      default: undefined
    },
    item_text: {
      type: String,
      default: ''
    },
    item_value: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: 'auto'
    },
    backgroundColor: {
      default: '#fff'
    },
    value: {
      default: null
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    eventChange(data) {
      this.$emit('onChange', data)
    }
  }
}
</script>

<style scoped>
.select-box-theme {
  border-radius: 4px;
  border-color: var(--bkk-darkblue);
  max-width: 226px;
}
</style>
