import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/product/:id',
    name: 'ProductDetail',
    component: () => import('../views/ProductDetail')
  },
  {
    path: '/my-shopping-cart',
    name: 'Cart',
    component: () => import('../views/Cart')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/CheckoutStep')
  },
  {
    path: '/my-orders',
    name: 'MyOrders',
    component: () => import('../views/MyOrders')
  },
  {
    path: '/order/:id',
    name: 'OrderDetail',
    component: () => import('../views/OrderDetail')
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import('../views/ShoppingLive/ShoppingLive')
  },
  {
    path: '/live-history',
    name: 'LiveHistory',
    component: () => import('../views/ShoppingLive/LiveHistory')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy')
  },
  {
    path: '/terms-of-service',
    name: 'TermOfService',
    component: () => import('../views/TermsOfService')
  },
  {
    path: '/test-pusher',
    name: 'TestPusher',
    component: () => import('../views/TestPusher')
  },
  {
    path: '/payment-result',
    name: 'PaymentResult',
    component: () => import('../views/PaymentResult')
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound')
  }
]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
