<template>
  <div class="fill-height py-3">
    <Loading :loading="isLoading"></Loading>

    <!--    <v-layout class="mb-5 mb-md-12" :column="$vuetify.breakpoint.smAndDown" style="height: 100vh;">-->
    <!--&lt;!&ndash;      <v-flex xs5>&ndash;&gt;-->
    <!--&lt;!&ndash;        <BannerLabel text="New Arrivals"></BannerLabel>&ndash;&gt;-->
    <!--&lt;!&ndash;      </v-flex>&ndash;&gt;-->
    <!--      <v-flex xs12>-->
    <!--        <div style="height: 100%">-->
    <!--          <Banner :banners="banners"></Banner>-->
    <!--        </div>-->
    <!--      </v-flex>-->
    <!--    </v-layout>-->

    <v-container>
      <v-layout
        class="mb-5"
        :column="$vuetify.breakpoint.smAndDown"
        style="margin-top: 20px"
      >
        <v-flex xs4>
          <Categories
            :categories="categories"
            :selected="categorySelected"
            @onSelect="categorySelected = $event"
          ></Categories>
        </v-flex>
        <v-flex xs8>
          <v-layout
            justify-space-between
            align-center
            mb-7
            v-if="categorySelected.name !== categories[0].name"
            class="animate__animated animate__bounceIn"
          >
            <v-flex shrink>
              <div class="txt-head">{{ categorySelected.name }}</div>
            </v-flex>
<!--            <v-flex shrink flex-center-col>-->
<!--              <div class="txt-bigger mr-2">FILTER</div>-->
<!--              <SelectBox></SelectBox>-->
<!--            </v-flex>-->
          </v-layout>
          <v-layout wrap>
            <v-flex xs6 md4 v-for="(item, i) in products" :key="i">
              <ProductSlot
                :img="item.photos"
                :name="item.name"
                :main_sku="item.main_sku"
                :price="item.price"
                :sizes="item.sku_size_stock_list"
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? []
                    : [(i + 1) % 3 == 0 ? 'pr-0' : 'mr-n5']
                "
                @onSeeDetail="seeDetail(item.id)"
                @onAddToCart="(stock_id) => quickAdd(stock_id)"
              ></ProductSlot>
            </v-flex>
          </v-layout>
          <div class="text-center mb-3" v-if="products.length <= 6">
            <Button label="More..." @onClick="loadMoreProducts"></Button>
          </div>
          <div class="flex-center-row">
            <Divider></Divider>
          </div>
        </v-flex>
      </v-layout>

      <!--      <v-layout class="mb-5 mb-md-12" :column="$vuetify.breakpoint.smAndDown">-->
      <!--        <v-flex xs7 :order-1="$vuetify.breakpoint.smAndDown">-->
      <!--          <div :style="$vuetify.breakpoint.mdAndUp ? {'height': '445px'}:{}">-->
      <!--            <Banner :banners="banners"></Banner>-->
      <!--          </div>-->
      <!--        </v-flex>-->
      <!--        <v-flex xs5>-->
      <!--          <BannerLabel text="BEST SELLER"></BannerLabel>-->
      <!--        </v-flex>-->
      <!--      </v-layout>-->
    </v-container>
  </div>
</template>

<script>
import Divider from '../components/Divider'
import Loading from '../components/Loading'
import Categories from '../components/Categories'
import ProductSlot from '../components/ProductSlot'
import BannerLabel from '../components/BannerLabel'
import Banner from '../components/Banner'
import Button from '../components/input/Button'
import SelectBox from '../components/input/SelectBox'
import { sleep } from '../plugins/sleep'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    Loading,
    Categories,
    ProductSlot,
    // BannerLabel,
    // Banner,
    Button,
    Divider,
    // SelectBox
  },
  data() {
    return {
      atPageBottom: false,
      isLoading: false,
      banners: [],
      categorySelected: { name: 'All' }
    }
  },
  async created() {
    this.isLoading = true
    this.banners = [
      require('../assets/img/banner/1.jpg'),
      require('../assets/img/banner/2.jpg')
    ]
    await this.$store.dispatch('Categories/onGetAllCategories')
    await this.$store.dispatch('Products/onGetProducts')
    this.categorySelected = this.categories[0]
    this.isLoading = false
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.atPageBottom = this.bottomVisible()
    })
  },
  computed: {
    ...mapState({
      categories: (state) => state.Categories.categories,
      accessToken: (state) => state.Auth.userDetail.access_token,
      products(state) {
        if (!state.Products.products || state.Products.products.length === 0) {
          return []
        }
        // All category
        if (!this.categorySelected.id) {
          return state.Products.products
        }
        // Filtering categories
        let filtered = state.Products.products.filter(
          (x) => x.category_id === this.categorySelected.id
        )
        return filtered
      }
    })
  },
  watch: {
    async atPageBottom(atPageBottom) {
      // use sleep for avoid browser overload
      await sleep(200)
      if (atPageBottom) {
        this.loadMoreProducts()
      }
    }
  },
  methods: {
    seeDetail(id) {
      this.$router.push({ name: 'ProductDetail', params: { id: id } })
    },
    async quickAdd(stock_id) {
      // only 3 fields required then action in Cart.js
      let product = {
        access_token: this.accessToken,
        product_stock_id: stock_id
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/add', product)
      this.isLoading = false
      this.$store.dispatch('Cart/popUpDialog')
    },
    bottomVisible() {
      // detect bottom func
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + Math.ceil(scrollY) >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    async loadMoreProducts() {
      // code below this was commented for unlimited scrolling down
      // let limit = 18
      // if (this.products.length >= limit) {
      //   return;
      // }
      // TODO: load next page api
    }
  }
}
</script>
