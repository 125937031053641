<template>
  <div class="pa-5">
    <v-hover v-slot="{ hover }">
      <v-img
        :src="img && img[0] && img[0].image_url ? img[0].image_url : require('../assets/img/no-image.png')"
        aspect-ratio="0.67"
        class="pointer"
        contain
        width="100%"
        @click="eventClick"
      >
        <div class="pb-3 flex-center-row fill-height align-end" style="border: solid 1px rgba(0,0,0,.1);">
          <div
            v-if="hover || showHoverOnMobile"
            class="highlight-txt add-button animate__animated animate__bounceIn pt-3 pb-1 px-2"
            style="width: 90%; cursor: default"
            @click.stop
          >
            <div class="text-center mb-3" v-if="sizes && sizes.length > 0">ADD TO BAG</div>
            <div class="text-center mb-3" v-else>Coming soon</div>
            <div class="d-flex flex-wrap justify-center">
              <v-btn
                class="mb-2 mr-2"
                v-for="(item, i) in sizes"
                :key="i"
                :disabled="itemQuantityInCart(item.id) >= item.stock"
                color="var(--bkk-darkblue)"
                elevation="0"
                min-width="0"
                small
                @click.stop="eventAdd(item.id)"
              >
                <span style="color: #fff">{{ item.size }}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-img>
    </v-hover>
    <div class="my-2">{{ name }}</div>
    <div class="highlight-txt" v-if="price !== 0">SGD
      {{ formatNumber(price, 2) }}
    </div>
    <div class="highlight-txt blue--text" v-else>
      Coming soon
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { formatNumber } from "@/plugins/global-funcs";

export default {
  name: 'ProductSlot',
  props: {
    img: {
      default: undefined
    },
    name: {
      default: '-'
    },
    main_sku: {
      default: ''
    },
    price: {
      default: 0
    },
    sizes: {
      default: []
    }
  },
  data () {
    return {
      formatNumber: formatNumber,
      showHoverOnMobile: false
    }
  },
  computed: {
    ...mapState({
      inCart: (state) => state.Cart.inCart,
      accessToken: state => state.Auth.userDetail.access_token
    })
  },
  methods: {
    eventClick() {
      if (this.$vuetify.breakpoint.smAndDown && !this.showHoverOnMobile) {
        this.showHoverOnMobile = !this.showHoverOnMobile
        return
      }
      this.$emit('onSeeDetail')
    },
    eventAdd(stock_id) {
      if (!this.accessToken) {
        this.$store.commit('Auth/loginDialogHandle', true)
        return
      }
      this.$emit('onAddToCart', stock_id)
    },
    itemQuantityInCart(id) {
      let item = this.inCart.filter((x) => x.product_stock_id === id)
      if (item.length > 0) {
        return item[0].quantity
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.highlight-txt {
  font-size: 18px;
  color: var(--bkk-txt-color) !important;
  &.add-button {
    background: rgba(255, 255, 255, 0.75);
    border-radius: 8px;
  }
}
</style>
