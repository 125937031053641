import Service from '../../services'

const state = {
  categories: [{ name: 'All' }]
}

const getters = {}

const actions = {
  onGetAllCategories({ commit }) {
    return Service.categories.getAllCategories().then((res) => {
      if (res.status === 200) {
        commit('setCategories', res.data.categories)
        return true
      }
    })
  }
}

const mutations = {
  setCategories(state, data) {
    state.categories = [{ name: 'All' }, ...data]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
