<template>
  <v-layout justify-center>
    <v-btn
      v-if="!lock"
      :disabled="value <= 1"
      class="button-theme"
      min-width="20px"
      outlined
      x-small
      @click="decrease"
    >
      <v-icon x-small>remove</v-icon>
    </v-btn>
    <div class="mx-2 text-center" style="min-width: 25px">{{ value }}</div>
    <v-btn
      v-if="!lock"
      :disabled="quantityExceed"
      class="button-theme"
      min-width="20px"
      outlined
      x-small
      @click="increase"
    >
      <v-icon x-small>add</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
export default {
  name: 'MiniQuantitySelector',
  props: {
    value: {
      default: 1
    },
    lock: {
      default: false
    },
    quantityExceed: {
      default: false
    }
  },
  methods: {
    increase() {
      if (!this.value) {
        return
      }
      this.$emit('increase')
    },
    decrease() {
      if (!this.value) {
        return
      }
      if (this.value > 1) {
        this.$emit('decrease')
      }
    }
  }
}
</script>

<style scoped>
.button-theme {
  border: solid 1px var(--bkk-darkblue);
  background: var(--bkk-darkblue);
  color: #fff !important;
}

.button-theme.v-btn--disabled {
  background: #fff;
  border: solid 1px rgba(0, 0, 0, 0.26);
}

.v-btn:not(.v-btn--round).v-size--x-small {
  padding: 0;
}
</style>
