<template>
  <v-dialog
      :value="value"
      :width="$vuetify.breakpoint.smAndDown ? '100%' : '500'"
      persistent
  >
    <v-card class="pa-5" style="border-radius: 12px">
      <v-btn x-small right absolute fab elevation="0" @click="eventClose">
        <v-icon>close</v-icon>
      </v-btn>
      <div class="text-center txt-m-large mb-3">Welcome to MyBKKShop</div>
      <div class="mb-4 text-center">Please login with Facebook</div>
      <Signin :only-facebook-login="true"></Signin>
    </v-card>
  </v-dialog>
</template>

<script>
import Signin from "@/views/StepPages/Signin"

export default {
  name: "LoginDialog",
  components: {
    Signin
  },
  props: {
    value: {
      default: false
    }
  },
  methods: {
    eventClose () {
      this.$store.commit('Auth/loginDialogHandle', false)
    }
  }
}
</script>

<style scoped>

</style>
