import axios from "axios";
import { api_domain } from "@/config";

const payment = {
    handlePayment (data) {
        return axios({
            url: api_domain + 'api/handle-payment',
            data: {
                access_token: data.access_token,
                cart_id: data.cart_id,
                address_id: data.address_id,
                description: data.description
            },
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on handle-payment\n', e.message)
            return { status: e.response.status }
        })
    }
}

export default payment
