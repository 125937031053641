<template>
  <div>
    <div
      class="flex-center-col"
      :class="{ 'justify-space-between flex-row-reverse': reverse }"
    >
      <v-checkbox
        class="ma-0"
        color="var(--bkk-darkblue)"
        dense
        :ripple="ripple"
        hide-details
        :off-icon="offIcon"
        :on-icon="onIcon"
        :readonly="readonly"
        :input-value="isCheck ? isCheck : isNowCheck"
        :disabled="disabled"
        @change="eventChange((isNowCheck = !isNowCheck))"
      ></v-checkbox>
      <div
        class="pt-2 grey--text"
        :class="{ pointer: !readonly }"
        @click="readonly ? null : (isNowCheck = !isNowCheck)"
      >
        {{ showAmount ? label + ' (' + amount + ')' : label }}
      </div>
    </div>
    <!-- Subcategory -->
    <div v-if="child.length > 0 && (isNowCheck || isCheck)" class="pl-7 py-2">
      <div class="mb-2">
        <div
          class="flex-center-col"
          v-for="(item, i) in loadMore ? child.slice(0, 4) : child"
          :key="i"
        >
          <v-checkbox
            class="ma-0"
            color="var(--bkk-darkblue)"
            dense
            ripple
            hide-details
            v-model="isSubCheck[i]"
            @change="onSubChange($event, item.id)"
          ></v-checkbox>
          <div class="pt-2 grey--text">
            {{
              item.quantity ? item.name + ' (' + item.quantity + ')' : item.name
            }}
          </div>
        </div>
      </div>
      <!-- load more -->
      <span
        v-if="child.length > 4 && loadMore"
        class="seemore grey--text txt-detail font-weight-bold"
        @click="loadMore = false"
        >See more</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props: {
    label: {
      default: ''
    },
    child: {
      default: Array
    },
    amount: {
      default: 0
    },
    showAmount: {
      default: false
    },
    readonly: {
      default: false
    },
    isCheck: {
      default: false
    },
    isSubCheck: {
      default: () => []
    },
    ripple: {
      default: true
    },
    offIcon: {
      default: undefined
    },
    onIcon: {
      default: undefined
    },
    reverse: {
      default: false
    },
    disabled: {
      default: false
    }
  },
  computed: {
    moreThanFour: function () {
      return this.child.length > 4
    }
  },
  watch: {
    isNowCheck: function () {
      if (
        (this.isNowCheck === false || this.isCheck === false) &&
        this.moreThanFour
      ) {
        this.loadMore = true
      }
    }
  },
  data: () => ({
    loadMore: false,
    isNowCheck: false
  }),
  created() {
    if (this.moreThanFour) {
      this.loadMore = true
    }
  },
  methods: {
    eventChange(val) {
      this.$emit('onChange', val)
    },
    onSubChange(val, id) {
      this.$emit('submodel', id)
    }
  }
}
</script>

<style scoped>
.seemore {
  cursor: pointer;
  user-select: none;
}
.seemore:hover {
  text-decoration: underline;
}
</style>
