<template>
  <v-snackbar
    top
    bottom
    right
    :value="model"
    :timeout="timeout"
    light
    elevation="0"
    tile
    color="transparent"
  >
    <v-sheet class="pa-5" width="430px" style="border: solid 1px #c4c0c0">
      <v-btn absolute small icon style="top: 30px" @click="eventDismiss">
        <v-icon>close</v-icon>
      </v-btn>
      <div class="text-center txt-bigger darkblue-color">
        {{ inCart.length > 0 ? 'Item added to Bag' : 'My Bag' }}
      </div>
      <Divider border-width-additional=".5px" long="100%"></Divider>
      <div
        style="position: relative; overflow: hidden auto; max-height: 325px"
        v-if="inCart.length > 0"
      >
        <div v-for="(item, i) in inCart" :key="i">
          <v-layout>
            <v-flex shrink mr-3>
              <v-img
                  width="100px"
                  height="140px"
                  :src="item.product && item.product.photos && item.product.photos.length > 0 ? item.product.photos[0].image_url:require('../assets/img/no-image.png')"
              ></v-img>
            </v-flex>
            <v-flex>
              <div class="mb-3">{{ nameOfProduct(item) }}</div>
              <div class="mb-3">SGD {{ formatNumber(item.price_per_piece, 2) }}</div>
              <v-layout justify-space-between mb-3>
                <v-flex shrink>
                  <div>size: {{ item.size }}</div>
                </v-flex>
                <v-flex shrink>
                  <v-btn x-small icon @click="eventRemoveItem(item.product_stock_id)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout justify-space-between>
                <v-flex shrink>
                  <MiniQuantitySelector
                    :value="item.quantity"
                    :quantity-exceed="item.quantity >= stockCount(item)"
                    @increase="eventIncreaseItem(item.product_stock_id)"
                    @decrease="eventDecreaseItem(item.product_stock_id)"
                  ></MiniQuantitySelector>
                </v-flex>
                <v-flex shrink>
                  <div>
                    Sub Total: SGD
                    {{ formatNumber(item.total_price, 2) }}
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <Divider border-width-additional=".5px" long="100%"></Divider>
        </div>
      </div>
      <div v-else class="grey--text text-center mb-5">Cart is empty</div>
      <div class="text-end txt-bigger darkblue-color mb-3">
        Sub Total: SGD {{ formatNumber(cartDetail.total_price, 2) }}
      </div>
      <Button
        type="dark"
        label="VIEW MY SHOPPING BAG"
        :block="true"
        @onClick="eventViewBag"
      ></Button>
    </v-sheet>
  </v-snackbar>
</template>

<script>
import Divider from './Divider'
import Button from './input/Button'
import MiniQuantitySelector from './input/MiniQuantitySelector'
import { mapState } from 'vuex'
import { formatNumber } from "@/plugins/global-funcs";

export default {
  name: 'CartPreviewDialog',
  props: {
    model: {
      default: false
    },
    timeout: {
      default: 4000
    }
  },
  data () {
    return {
      formatNumber: formatNumber
    }
  },
  components: {
    Divider,
    Button,
    MiniQuantitySelector
  },
  computed: {
    ...mapState({
      inCart: (state) => state.Cart.inCart,
      cartDetail: state => state.Cart.cartDetail
    })
    // subTotal: function () {
    //   let sum = 0.0
    //   for (const x of this.inCart) {
    //     sum += parseFloat(x.size.price * x.quantity, 2)
    //   }
    //   return parseFloat(sum) || parseFloat(sum) == 0
    //     ? formatNumber(sum, 2)
    //     : '-'
    // }
  },
  methods: {
    eventDismiss() {
      this.$emit('onDismiss')
    },
    eventViewBag() {
      this.$emit('onViewBag')
    },
    eventIncreaseItem(product_stock_id) {
      this.$emit('increaseItem', product_stock_id)
    },
    eventDecreaseItem(product_stock_id) {
      this.$emit('decreaseItem', product_stock_id)
    },
    eventRemoveItem(product_stock_id) {
      this.$emit('removeItem', product_stock_id)
    },
    stockCount (item) {
      return item.product_stock ? item.product_stock.stock : 0
    },
    nameOfProduct (item) {
      return item.product ? item.product.name : '-'
    }
  }
}
</script>

<style scoped>
.v-snack {
  top: 70px;
}
</style>
