<template>
  <div class="min-screen-support">
    <div id="app">
      <v-app>
        <Header></Header>
        <div style="height: 145px"></div>

        <router-view />
        <LoginDialog :value="!isLogin && loginDialog"></LoginDialog>

        <Footer></Footer>
      </v-app>
    </div>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import { mapState } from 'vuex'
import { initFbSdk } from "@/plugins/facebook-sdk";
import { app_id } from "@/config";
import LoginDialog from "@/components/LoginDialog";

export default {
  components: {
    Header,
    Footer,
    LoginDialog
  },
  computed: {
    ...mapState({
      isLogin: (state) => !!state.Auth.fbAuth.authResponse.accessToken,
      loginDialog: state => state.Auth.loginDialog
    })
  },
  mounted() {
    // init facebook sdk
    initFbSdk(app_id)
  }
}
</script>

<style>
#app {
  font-family: Kanit, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}
.min-screen-support {
  position: relative;
  overflow: auto;
}
</style>
