<template>
  <div>
    <div v-if="!onlyFacebookLogin">
      <Loading :loading="isLoading"></Loading>
      <!-- Sign up -->
      <div v-if="signupSection">
        <div class="txt-head mb-5">Personal Information</div>
        <div class="layout-border pa-5">
          <div class="txt-bigger mb-5">Your login credentials</div>
          <div class="mb-5">
            <TextField
              placeholder="Email"
              :disabled="true"
              :value="signupEmail"
            ></TextField>
          </div>
          <div class="mb-5">
            <TextField
              type="password"
              placeholder="Password"
              :value="signupDetail.password"
              @onChange="eventSetPassword"
            ></TextField>
          </div>
          <div class="mb-5">
            <TextField
              type="password"
              placeholder="Retype Password"
              :value="confirmPassword"
              @onChange="confirmPassword = $event"
            ></TextField>
          </div>
          <div class="txt-bigger mb-5">Your personal information</div>
          <div class="mb-5">
            <TextField
              placeholder="First name"
              :value="signupDetail.firstname"
              @onChange="eventSetFirstname"
            ></TextField>
          </div>
          <div class="mb-5">
            <TextField
              placeholder="Last name"
              :value="signupDetail.lastname"
              @onChange="eventSetLastname"
            ></TextField>
          </div>
          <div class="txt-bigger mb-5">Date of Birth</div>
          <div class="mb-5">
            <SelectBox full-width class="bd-selectbox day mr-2"></SelectBox>
            <SelectBox full-width class="bd-selectbox month mr-2"></SelectBox>
            <SelectBox full-width class="bd-selectbox year"></SelectBox>
          </div>
          <CheckBox
            class="mb-5"
            label="I have read and understood MyBkkShop Privacy Policy"
          ></CheckBox>
          <Button type="dark" label="REGISTER" @onClick="onRegister"></Button>
        </div>
      </div>
      <!-- Sign in -->
      <div v-else>
        <div class="txt-bigger mb-3">Login</div>
        <div class="mb-5">
          <TextField
            type="email"
            placeholder="Email"
            :disabled="onlyFacebookLogin"
          ></TextField>
        </div>
        <div class="mb-5">
          <TextField
            type="password"
            placeholder="Password"
            :disabled="onlyFacebookLogin"
          ></TextField>
        </div>
        <div class="mb-3">
          <span class="grey--text txt-detail pointer text-decoration-underline"
            >Forgot your password ?</span
          >
        </div>
        <v-layout :column="$vuetify.breakpoint.smAndDown">
          <Button
            class="mr-md-3 mb-md-0 mb-5"
            type="dark"
            label="LOGIN"
            @onClick="eventLogin"
            :disabled="onlyFacebookLogin"
          ></Button>
          <Button
            class="mb-10"
            type="dark"
            label=" LOGIN WITH FACEBOOK"
            :prepend-res-icon="require('../../assets/img/social/fb-icon.png')"
            res-size="14px"
            @onClick="eventLoginFacebook"
          ></Button>
        </v-layout>
        <div class="txt-bigger">New Customer ?</div>
        <div class="txt-detail mb-3">
          Please enter your email address create an account to start your
          shopping journey.
        </div>
        <div class="mb-5">
          <TextField
            type="email"
            placeholder="Email"
            :value="signupEmail"
            :disabled="onlyFacebookLogin"
            @onChange="eventChangeEmail"
          ></TextField>
        </div>
        <Button
          class="mb-5"
          type="dark"
          label="CREATE AN ACCOUNT"
          @onClick="eventCreateAcc"
          :disabled="onlyFacebookLogin"
        ></Button>
      </div>
    </div>
    <div v-else>
      <v-btn block color="#3B5998" @click="eventLoginFacebook">
        <v-layout justify-center>
          <v-flex shrink>
            <v-img
              width="20px"
              max-height="20px"
              contain
              class="mr-2"
              :src="require('../../assets/img/social/fb-icon.png')"
            ></v-img>
          </v-flex>
          <span class="white--text"> LOGIN WITH FACEBOOK </span>
        </v-layout>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Button from '../../components/input/Button'
import TextField from '../../components/input/TextField'
import SelectBox from '../../components/input/SelectBox'
import CheckBox from '../../components/input/CheckBox'
import Loading from '../../components/Loading'
import { mapState } from 'vuex'

export default {
  components: {
    Button,
    TextField,
    SelectBox,
    CheckBox,
    Loading
  },
  props: {
    signupEmail: {
      default: ''
    },
    signupDetail: {
      default: null
    },
    onlyFacebookLogin: {
      default: false
    }
  },
  data() {
    return {
      signupSection: false,
      isLoading: false,
      confirmPassword: ''
    }
  },
  computed: {
    ...mapState({
      userDetail: (state) => state.Auth.userDetail
    })
  },
  methods: {
    eventCreateAcc() {
      if (this.signupEmail && this.validateEmail(this.signupEmail)) {
        this.signupSection = true
      } else {
        this.$swal.fire({
          ...this.$swalError,
          text: 'Please correct your email'
        })
      }
    },
    eventLogin() {
      this.$emit('onLogin')
    },
    validateEmail(email) {
      const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      return re.test(email)
    },
    eventChangeEmail(input) {
      this.$emit('onChangeSignupEmail', input)
    },
    onRegister() {
      if (!this.validateRegister()) {
        this.$swal.fire({
          ...this.$swalError,
          text:
            'Please correct your information and re-type password to confirm'
        })
        this.confirmPassword = ''
        return
      }
      // register api
      //
      this.$emit('onSignupSuccess')
    },
    validateRegister() {
      let pass = true
      if (!this.signupDetail.firstname || !this.signupDetail.lastname) {
        pass = false
      }
      if (this.confirmPassword === this.signupDetail.password) {
        pass = false
      }
      return pass
    },
    eventSetPassword(val) {
      this.$emit('setPassword', val)
    },
    eventSetFirstname(val) {
      this.$emit('setFirstname', val)
    },
    eventSetLastname(val) {
      this.$emit('setLastname', val)
    },
    async eventLoginFacebook() {
      this.isLoading = true
      let _this = this
      window.FB.login(
        function (res) {
          if (res.authResponse) {
            window.FB.api(
              '/me',
              'get',
              {
                fields:
                  'id,name,email,first_name,last_name,picture.width(150).height(150)'
              },
              function (response) {
                // console.log(response)
                _this.$store
                  .dispatch('Auth/onFbRegister', { auth: res, data: response })
                  .then(() => {
                    _this.$store.dispatch('Cart/getCart', _this.userDetail.access_token)
                  })
                _this.isLoading = false
              }
            )
          } else {
            _this.isLoading = false
            _this.$swal.fire({
              ..._this.$swalError,
              text: 'Login failed.'
            })
          }
        },
        { scope: 'email' }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.layout-border {
  border: solid 2px #c4c4c4;
}
.bd-selectbox {
  display: inline-block;
  &.day {
    width: 70px;
  }
  &.month {
    width: 90px;
  }
  &.year {
    width: 90px;
  }
}
</style>
