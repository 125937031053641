import Service from '../../services'

const state = {

}

const getters = {

}

const actions = {
    onHandlePayment ({ commit }, data) {
        return Service.payment.handlePayment(data).then(res => {
            return res
        })
    }
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
