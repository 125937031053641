export function formatNumber (x, toFixed = 0) {
    if (!x && x != 0) {
        return x
    }
    if (toFixed === 0) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else if (toFixed === 2) {
        return parseFloat(x)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
}

export function dataIsCorrect (x) {
    return x !== null && x !== undefined && x !== ''
}
