<template>
  <div class="pa-10 flex-center" style="position: relative; height: 100%">
    <v-img
      :src="require('../assets/img/bestseller.png')"
      class="full-absolute"
    ></v-img>
    <div class="py-4 px-10 banner-label flex-center txt-large">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerLabel',
  props: {
    text: {
      default: ''
    }
  }
}
</script>

<style scoped>
.banner-label {
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
</style>
