import { api_domain } from '../../config'
import axios from 'axios'
import ServiceAPI from '../../services/HttpClient'

const address = {
  getAddress(payload) {
    const url = api_domain + 'api/get-addresss'
    return ServiceAPI.post(url, payload)
  },

  saveNewAddress(payload) {
    const url = api_domain + 'api/create-addresss'
    return ServiceAPI.post(url, payload)
  },

  getDetailbyID(payload) {
    const url = api_domain + 'api/detail-addresss'
    return ServiceAPI.post(url, payload)
  },

  getShippingCost (data) {
    return axios({
      url: api_domain + `api/calculate-shipping-price/${data.cart_id}/${data.address_id}`,
      method: 'GET'
    }).catch(e => {
      console.log('Catch error on getShippingCost\n', e.message)
      return { status: e.response.status }
    })
  }

  // addItemToCart(id) {
  //   return axios({
  //     url: api_domain + 'api/add-cart/' + id,
  //     method: 'GET'
  //   }).catch((e) => {
  //     console.log('Catch error on addItemToCart\n', e.message)
  //     return { status: e.response.status }
  //   })
  // }
}

export default address
