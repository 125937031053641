import Service from '../../services'

const state = {
  userAddress: {},
  useAddressID: null,
  shippingCost: 0
}

const getters = {
  getUserAddress: (state, getters) => {
    return getters.state.userAddress
  }
}

const actions = {
  onSelectAddress({ commit }, data) {
    commit('setUserAddress', data)
    return true
  },
  useAddressID({ commit }, data) {
    commit('setUseAddressID', data)
    return true
  },
  onGetShippingCost ({ commit }, data) {
    return Service.address.getShippingCost(data).then(res => {
      console.log(res)
      if (res.status === 200) {
        commit('setShippingCost', res.data.shipping_price)
      }
    })
  }
}

const mutations = {
  setUseAddressID(state, data) {
    state.useAddressID = data
  },
  setUserAddress(state, data) {
    state.userAddress = data
  },
  clearAddress(state) {
    state.userAddress = {}
  },
  setShippingCost(state, data) {
    state.shippingCost = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
