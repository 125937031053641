import Vue from 'vue'
import swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.prototype.$swal = swal
// Preset for delete confirmation dialog
Vue.prototype.$swalDel = {
  icon: 'warning',
  confirmButtonColor: 'var(--bkk-darkblue)',
  confirmButtonText: '<span>Yes</span>',
  cancelButtonText: '<span>No</span>',
  showCancelButton: true,
  // buttonsStyling: false,
  // focusCancel: true,
  // customClass: {
  //   confirmButton: 'button-theme swal-button confirm text-label',
  //   // cancelButton: 'button-theme swal-button confirm text-label'
  //   cancelButton: 'cancel-button-theme swal-button text-label',
  //   content: 'kcg-d-color lib-font txt-bigger'
  // },
  showClass: {
    popup: 'animate__animated animate__flipInX'
  },
  hideClass: {
    popup: 'animate__animated animate__flipOutX'
  }
}

// Preset for error on save pr items dialog (reuse by replace text property)
Vue.prototype.$swalError = {
  icon: 'error',
  title: '<span>Error !</span>',
  confirmButtonColor: 'var(--bkk-darkblue)',
  confirmButtonText: '<span>OK</span>',
  // buttonsStyling: false,
  // customClass: {
  //   confirmButton: 'button-theme swal-button confirm text-label',
  //   content: 'kcg-m-color lib-font txt-detail'
  // },
  showClass: {
    popup: 'animate__animated animate__flipInX'
  },
  hideClass: {
    popup: 'animate__animated animate__flipOutX'
  }
}

// Preset for success dialog
Vue.prototype.$swalSuccess = {
  icon: 'success',
  title: '<span>Success !</span>',
  confirmButtonColor: 'var(--bkk-darkblue)',
  confirmButtonText: '<span>OK</span>',
  // buttonsStyling: false,
  // customClass: {
  //   confirmButton: 'button-theme swal-button confirm text-label',
  //   content: 'kcg-m-color lib-font txt-detail'
  // },
  showClass: {
    popup: 'animate__animated animate__flipInX'
  },
  hideClass: {
    popup: 'animate__animated animate__flipOutX'
  }
}

// Preset for success dialog
Vue.prototype.$swalInfo = {
  icon: 'info',
  confirmButtonColor: 'var(--bkk-darkblue)',
  confirmButtonText: '<span>OK</span>',
  // buttonsStyling: false,
  // customClass: {
  //   confirmButton: 'button-theme swal-button confirm text-label',
  //   content: 'kcg-m-color lib-font txt-detail'
  // },
  showClass: {
    popup: 'animate__animated animate__flipInX'
  },
  hideClass: {
    popup: 'animate__animated animate__flipOutX'
  }
}

export default swal
