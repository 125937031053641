import Service from '../../services'
import Cart from './Cart'

const state = {
  privacy_policy: '',
  terms_of_service: ''
}

const getters = {

}

const actions = {
  onGetPrivacyPolicy ({ commit }) {
    return Service.organization.getPrivacyPolicy().then(res => {
      if (res.status === 200) {
        commit('setPrivacyPolicy', res.data.result)
      }
    })
  },
  onGetTermsOfService ({ commit }) {
    return Service.organization.getTermsOfService().then(res => {
      if (res.status === 200) {
        commit('setTermsOfService', res.data.result)
      }
    })
  }
}

const mutations = {
  setPrivacyPolicy(state, data) {
    state.privacy_policy = data
  },
  setTermsOfService(state, data) {
    state.terms_of_service = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
