import auth from './api/auth'
import categories from './api/categories'
import products from './api/products'
import carts from './api/cart'
import address from './api/address'
import organization from './api/organization'
import live from './api/live'
import broadcast from './broadcast/broadcast'
import order from './api/order'
import payment from "@/services/api/payment";

export default {
  auth,
  categories,
  products,
  address,
  carts,
  live,
  organization,
  broadcast,
  order,
  payment
}
