import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/swal'
import './assets/css/styles.scss'
import './assets/css/font.css'
import 'animate.css'
import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

Vue.use(require('vue-moment'))

import ZoomOnHover from 'vue-zoom-on-hover'
Vue.use(ZoomOnHover)

Vue.config.productionTip = true

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
