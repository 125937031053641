import { api_domain } from '../../config'
import axios from 'axios'

const auth = {
  fbRegister(data) {
    return axios({
      url: api_domain + 'api/fb-register',
      data: data,
      method: 'POST'
    }).catch((e) => {
      console.log('Catch error on fbRegister\n', e.message)
      return { status: e.response.status }
    })
  }
}

export default auth
