import { api_domain } from '../../config'
import axios from 'axios'

const order = {
    getAllOrders(access_token) {
        return axios({
            url: api_domain + 'api/get-orders',
            data: { access_token: access_token },
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on getAllOrders\n', e.message)
            return { status: e.response.status }
        })
    },
    getOrder(data) {
        return axios({
            url: api_domain + 'api/get-order',
            data: {
                access_token: data.access_token,
                order_id: data.order_id
            },
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on getOrder\n', e.message)
            return { status: e.response.status }
        })
    },
    cancelOrder (data) {
        return axios({
            url: api_domain + 'api/cancel-payment',
            data: {
                access_token: data.access_token,
                order_id: data.order_id
            },
            method: 'POST'
        }).catch(e => {
            console.log('Catch error on cancel order\n', e.message)
            return { status: e.response.status }
        })
    }
}

export default order
