<template>
  <div class="pa-5 header-tab">
    <Loading :loading="isLoading"></Loading>
    <v-layout justify-space-between align-center>
      <v-flex md2 style="white-space: nowrap">
        <v-btn
          icon
          class="pa-2 mr-1"
          v-for="(item, i) in socialIcon"
          :key="i"
          @click="openLink(item.link)"
        >
          <v-img contain width="20px" :src="item.src"></v-img>
        </v-btn>
      </v-flex>
      <!--            <v-flex xs4 style="white-space: nowrap;" v-else>-->
      <!--&lt;!&ndash;                <v-btn&ndash;&gt;-->
      <!--&lt;!&ndash;                        icon class="pa-2 mr-1"&ndash;&gt;-->
      <!--&lt;!&ndash;                        @click="sidebar = !sidebar"&ndash;&gt;-->
      <!--&lt;!&ndash;                >&ndash;&gt;-->
      <!--&lt;!&ndash;                    <v-img&ndash;&gt;-->
      <!--&lt;!&ndash;                            contain&ndash;&gt;-->
      <!--&lt;!&ndash;                            width="20px"&ndash;&gt;-->
      <!--&lt;!&ndash;                            style="display: inline-block"&ndash;&gt;-->
      <!--&lt;!&ndash;                            :src="require('../assets/img/menu/menu.png')"&ndash;&gt;-->
      <!--&lt;!&ndash;                    ></v-img>&ndash;&gt;-->
      <!--&lt;!&ndash;                </v-btn>&ndash;&gt;-->
      <!--            </v-flex>-->

      <!--            <v-flex md2 class="text-center" v-if="$vuetify.breakpoint.mdAndUp">-->
      <!--                <div-->
      <!--                        class="pointer header-text d-inline txt-m-head pb-2"-->
      <!--                        :class="{'header-active': $route.name === 'Home'}"-->
      <!--                        @click="routeTo('Home')"-->
      <!--                >New Arrivals</div>-->
      <!--            </v-flex>-->
      <v-flex shrink style="width: 100%">
        <v-img
          style="margin: 0 auto"
          :src="require('../assets/img/my-bkk.png')"
          contain
          :width="$vuetify.breakpoint.smAndDown ? '50px' : '70px'"
          class="pointer"
          @click="routeTo('Home')"
        ></v-img>
      </v-flex>
      <!--            <v-flex md2 class="text-center" v-if="$vuetify.breakpoint.mdAndUp">-->
      <!--                <div-->
      <!--                        class="pointer header-text txt-m-head d-inline pb-2"-->
      <!--                >Product</div>-->
      <!--            </v-flex>-->
      <v-flex text-end
        shrink
        style="white-space: nowrap; position: relative"
      >
        <v-btn class="pa-2 mr-5" small elevation="0"
         :class="{'live-active': liveSource !== ''}"
        @click="routeTo('LiveHistory')"
        color="error"
        >
          LIVE
        </v-btn>
      </v-flex>
      <v-flex
        text-end
        :class="$vuetify.breakpoint.smAndDown ? ['xs4'] : ['md2']"
        style="white-space: nowrap; position: relative"
      >
<!--        <div v-if="$vuetify.breakpoint.smAndDown">-->
<!--          <v-btn icon class="pa-2 mr-1">-->
<!--            <v-img-->
<!--              contain-->
<!--              width="20px"-->
<!--              style="display: inline-block"-->
<!--              :src="require('../assets/img/menu/search.png')"-->
<!--            ></v-img>-->
<!--          </v-btn>-->
<!--          <v-btn icon class="pa-2 mr-1" v-if="isLogin" @click="routeTo('Cart')">-->
<!--            <v-badge-->
<!--              overlap-->
<!--              color="#333"-->
<!--              :value="!!cartCount"-->
<!--              :content="cartCount"-->
<!--            >-->
<!--              <v-img-->
<!--                contain-->
<!--                width="20px"-->
<!--                style="display: inline-block"-->
<!--                :src="require('../assets/img/menu/bag.png')"-->
<!--              ></v-img>-->
<!--            </v-badge>-->
<!--          </v-btn>-->
<!--        </div>-->
        <div>
          <v-btn icon class="pa-2 mr-1">
            <v-img
              contain
              width="20px"
              style="display: inline-block"
              :src="require('../assets/img/menu/search.png')"
            ></v-img>
          </v-btn>
          <v-menu offset-y v-if="isLogin">
            <template v-slot:activator="{ on }">
              <v-btn icon class="pa-2 mr-1" v-on="on">
                <v-img
                  contain
                  width="20px"
                  style="display: inline-block"
                  :src="require('../assets/img/menu/person.png')"
                ></v-img>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="routeTo('MyOrders')">
                <div>My orders</div>
              </v-list-item>
              <v-list-item @click="onLogout">
                <div style="color: #c62121">Log out</div>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon class="pa-2 mr-1" @click="routeTo('Cart', true)">
            <v-badge
              overlap
              color="#333"
              :value="!!cartCount"
              :content="cartCount"
            >
              <v-img
                contain
                width="20px"
                style="display: inline-block"
                :src="require('../assets/img/menu/bag.png')"
              ></v-img>
            </v-badge>
          </v-btn>
          <CartPreviewDialog
            v-if="$route.name != 'Cart' && $route.name != 'Checkout' && $route.name != 'ProductDetail'"
            :model="previewDialog"
            :timeout="dialogTimeout"
            @onDismiss="$store.dispatch('Cart/dismissDialog')"
            @onViewBag="$router.push({ name: 'Cart' })"
            @increaseItem="increaseItem"
            @decreaseItem="decreaseItem"
            @removeItem="removeItem"
          ></CartPreviewDialog>
        </div>
      </v-flex>
    </v-layout>

    <v-navigation-drawer
      fixed
      floating
      color="rgb(229,227,241)"
      v-model="sidebar"
    >
      <div class="pa-5">
        <!--                <div class="d-flex mb-5">-->
        <!--                    <v-btn-->
        <!--                            icon class="pa-2 mr-1"-->
        <!--                            @click="sidebar = !sidebar"-->
        <!--                    >-->
        <!--                        <v-icon>chevron_right</v-icon>-->
        <!--                    </v-btn>-->
        <!--                    <v-spacer></v-spacer>-->
        <!--                    <v-btn-->
        <!--                            icon class="pa-2 mr-1"-->
        <!--                    >-->
        <!--                        <v-img-->
        <!--                                contain-->
        <!--                                width="20px"-->
        <!--                                style="display: inline-block"-->
        <!--                                :src="require('../assets/img/menu/person.png')"-->
        <!--                        ></v-img>-->
        <!--                    </v-btn>-->
        <!--                    <v-btn-->
        <!--                            icon class="pa-2 mr-1"-->
        <!--                            @click="item.event"-->
        <!--                    >-->
        <!--                        <v-img-->
        <!--                                contain-->
        <!--                                width="20px"-->
        <!--                                style="display: inline-block"-->
        <!--                                :src="require('../assets/img/menu/bag.png')"-->
        <!--                        ></v-img>-->
        <!--                    </v-btn>-->
        <!--                </div>-->
        <!--                <div class="mb-3">-->
        <!--                    <div :class="{'active-node': $route.name === 'Home'}"></div>-->
        <!--                    <div-->
        <!--                            class="pointer header-text d-inline"-->
        <!--                            @click="routeTo('Home')"-->
        <!--                    >New Arrivals</div>-->
        <!--                </div>-->
        <!--                <div class="mb-3">-->
        <!--                    <div-->
        <!--                            class="pointer header-text d-inline"-->
        <!--                    >Product</div>-->
        <!--                </div>-->
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import CartPreviewDialog from './CartPreviewDialog'
import Loading from '../components/Loading'
import { mapState } from 'vuex'

export default {
  name: 'Header',
  components: {
    CartPreviewDialog,
    Loading
  },
  data() {
    return {
      isLoading: false,
      sidebar: false,
      socialIcon: [
        {
          src: require('../assets/img/social/fb.png'),
          link: 'https://www.facebook.com/mybkkshop/'
        },
        // {
        //     src: require('../assets/img/social/phone.png'),
        //     link: ''
        // },
        {
          src: require('../assets/img/social/ig.png'),
          link: 'https://www.instagram.com/mybkkshop/'
        }
      ]
    }
  },
  async created() {
    if (this.accessToken) {
      await this.$store.dispatch('Cart/getCart', this.accessToken)
    }
  },
  computed: {
    ...mapState({
      inCart: (state) => state.Cart.inCart,
      previewDialog: (state) => state.Cart.previewDialog,
      dialogTimeout: (state) => state.Cart.dialogTimeout,
      cartCount: (state) => state.Cart.inCart.length,
      liveSource: (state)=>state.Live.liveSource,
      isLogin: (state) => !!state.Auth.fbAuth.authResponse.accessToken,
      accessToken: (state) => state.Auth.userDetail.access_token
    }),
    desktopSize: function () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  watch: {
    desktopSize: function () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.sidebar = false
      }
    }
  },
  methods: {
    async routeTo(name, requireAuth = false) {
      if (!this.accessToken && requireAuth) {
        this.$store.commit('Auth/loginDialogHandle', true)
        return
      }
      if (this.$route.name !== name) {
        if (name === 'Cart') {
          await this.$store.dispatch('Cart/getCart', this.accessToken)
        }
        this.$router.push({ name: name })
      }
    },
    openLink(link) {
      if (link) {
        window.open(link)
      }
    },
    async onLogout() {
      this.isLoading = true
      await this.$store.dispatch('Auth/logOut')
      window.location = '/'
    },
    async increaseItem(product_stock_id) {
      let item = {
        access_token: this.accessToken,
        product_stock_id: product_stock_id
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/add', item)
      this.isLoading = false
    },
    async decreaseItem(product_stock_id) {
      let item = {
        access_token: this.accessToken,
        product_stock_id: product_stock_id
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/decreaseOne', item)
      this.isLoading = false
    },
    async removeItem(product_stock_id) {
      let item = {
        access_token: this.accessToken,
        product_stock_id: product_stock_id
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/removeItem', item)
      this.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.header-text {
  color: #756a63;
}
.header-active {
  border-bottom: solid 2px var(--bkk-theme);
}
.sidedock {
  position: fixed;
  z-index: 10;
  display: grid;
  top: calc(50% - 54px);
  left: 8px;
  background: #fff;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
}
.header-tab {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 20;
  background: #fff;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.2);
}
.fadeInOut-enter-active,
.fadeInOut-leave-active {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.fadeInOut-enter,
.fadeInOut-leave-to {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
.active-node {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 8px;
  background: var(--bkk-grey);
}
</style>
