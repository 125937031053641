import { api_domain } from '../../config'
import axios from 'axios'

const carts = {
  getCarts(access_token) {
    return axios({
      url: api_domain + 'api/get-cart',
      data: { access_token: access_token },
      method: 'POST'
    }).catch((e) => {
      console.log('Catch error on getCarts\n', e.message)
      return { status: e.response.status }
    })
  },

  increaseOneItemToCart(product) {
    return axios({
      url: api_domain + 'api/increase-product-cart',
      data: {
        access_token: product.access_token,
        product_stock_id: product.product_stock_id,
        quantity: product.quantity ? product.quantity : undefined
      },
      method: 'POST'
    }).catch((e) => {
      console.log('Catch error on increaseOneItemToCart\n', e.message)
      return { status: e.response.status }
    })
  },

  decreaseOneItemToCart(product) {
    return axios({
      url: api_domain + 'api/decrease-product-cart',
      data: {
        access_token: product.access_token,
        product_stock_id: product.product_stock_id,
        quantity: product.quantity ? product.quantity : undefined
      },
      method: 'POST'
    }).catch((e) => {
      console.log('Catch error on decreaseOneItemToCart\n', e.message)
      return { status: e.response.status }
    })
  },

  removeItemFromCart(product) {
    return axios({
      url: api_domain + 'api/remove-product-cart/',
      data: {
        access_token: product.access_token,
        product_stock_id: product.product_stock_id,
        quantity: product.quantity ? product.quantity : undefined
      },
      method: 'POST'
    }).catch((e) => {
      console.log('Catch error on removeItemFromCart\n', e.message)
      return { status: e.response.status }
    })
  }
}

export default carts
