// CONSTANTS
export const app_id = '388571069076540'
export const shop_id = 1

// Pusher
export const pusher_key = "69a0d04e0e9d4fd1be03"
export const pusher_cluster = "ap1"

// PRODUCTION API
// export const api_domain = 'https://api.mybkk.shop/'

// TEST SITE API
export const api_domain = 'https://mybkk.kisra.co.th/'

// LOCAL API
//export const api_domain = 'http://127.0.0.1:8000/'

