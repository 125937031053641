<template>
  <div class="my-5">
    <div class="txt-head mb-4">Categories</div>
    <div
      v-for="(item, i) in categories"
      :key="i"
      class="pl-10 mb-4 animate__animated animate__bounceInLeft animate__delay-1s"
    >
      <span
        class="category-style pointer"
        :class="{ 'category-selected': selected === item }"
        @click="eventSelectCategory(item)"
        >{{ item.name }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Categories',
  props: {
    categories: {
      default: ['All']
    },
    selected: {
      default: 'All'
    }
  },
  methods: {
    eventSelectCategory(category) {
      this.$emit('onSelect', category)
    }
  }
}
</script>

<style scoped>
.category-style {
  font-size: 18px;
  color: #898989;
}
.category-style:hover,
.category-selected {
  color: #000;
}
</style>
