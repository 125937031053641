<template>
  <div :class="vertical ? 'mx-3' : 'my-3'">
    <v-divider
      class="line-border"
      :vertical="vertical"
      :style="{ width: long, 'border-width': borderWidthAdditional }"
      style="border-radius: 15%"
    ></v-divider>
  </div>
</template>

<script>
export default {
  name: 'Divider',
  props: {
    long: {
      default: '300px'
    },
    vertical: {
      default: false
    },
    borderWidthAdditional: {
      default: '1.5px'
    }
  }
}
</script>

<style scoped lang="scss">
.line-border {
  border-color: var(--bkk-theme) !important;
}
</style>
